import React, {Component, RefObject} from "react";
import {Button, ButtonGroup, Form, FormControl, FormGroup, FormLabel, InputGroup} from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import {faClock, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Filter} from "./Filter";

export interface SearchProps {
  onSearch: (query: string) => void;
}

export interface SearchState {}

export class Search extends Component<SearchProps, SearchState> {
  static defaultProps = {};

  render() {
    return (
      <div className="search">
        <Form onSubmit={(evt) => evt.preventDefault()}>
            <InputGroup className={'input-group-lg'}>
              <FormControl type="text" placeholder="Tankstelle finden" onChange={(evt) => {
                this.props.onSearch(evt.target.value);
              }}/>
              
            </InputGroup>
        </Form>
      </div>
    );
  }
}
