import React, {Component} from "react";
import withParams, {Params} from "../Utils/withParams";
import {Tankstelle, TankstellenService} from "../Services/TankstellenService";
import {TankstelleDetails} from "../Components/TankstelleDetails";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";

export interface DetailProps extends Params, RouteComponentProps {
  params: { slug: string },
}

export interface DetailState {
  tankstelle: Tankstelle | undefined;
}

class Detail extends Component<DetailProps, DetailState> {
  static defaultProps = {};

  state: DetailState = {
    tankstelle: undefined,
  }

  componentDidMount() {
    if (this.props.params.slug)
      this.setState(() => ({
        tankstelle: TankstellenService.getInstance().getBySlug(this.props.params.slug),
      }));
  }

  render() {
    return (
      <>
        { this.state.tankstelle
          ? <TankstelleDetails tankstelle={this.state.tankstelle}/>
          : <p>Keine Tankstelle gefunden!</p>
        }
      </>
    );
  }
}

export default withRouter(withParams(Detail));