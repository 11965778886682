import {Component} from "react";
import {ClusterFeature} from "supercluster";
import {Tankstelle} from "../Services/TankstellenService";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {OverlayTrigger} from "react-bootstrap";
import TankstelleShortDetails from "./TankstelleShortDetails";
import Logo from '../images/roth_logo.jpg';
import gmap from "google-map-react";

const markerWidthInPx = 49;
const markerHeightInPx = 60;
const markerOriginX = markerWidthInPx * .5;
const markerOriginY = markerHeightInPx;

const markerStyle = {
  position: 'absolute',
  'background-image': `url(${Logo})`,
  'background-size': 'cover',
  cursor: 'pointer',
  width: `${markerWidthInPx}px`,
  height: `${markerHeightInPx}px`,
  top: `-${markerOriginY}px`,
  left: `-${markerOriginX}px`,
  'transform-origin': `${markerOriginX} ${markerOriginY}`,
  margin: 0,
  padding: 0,
} as const;

export interface MarkerProps extends RouteComponentProps {
  lat: number;
  lng: number;
  cluster: ClusterFeature<Tankstelle>;
  isActive?: boolean,
}

export interface MarkerState {}

export class Marker extends Component<MarkerProps, MarkerState> {
  static defaultProps = {
    isActive: false
  };

  constructor(props: MarkerProps) {
    super(props);
    this.toDetailPage = this.toDetailPage.bind(this);
  }

  toDetailPage = () => {
    const url = `/tankstellen/${this.props.cluster.properties.slug}`;
    this.props.history.push(url);
  }

  render() {
    return (
      <>
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<TankstelleShortDetails tankstelle={this.props.cluster.properties}/>}>
          {
            this.props.isActive
            ? (<div className="outer-marker outer-marker-red" onClick={this.toDetailPage} children={<div className="inner-marker"/>}/>)
            : (<div className="outer-marker" onClick={this.toDetailPage} children={<div className="inner-marker"/>}/>)
          }
        </OverlayTrigger>
      </>
    );
  }
}

export default withRouter(Marker);