import {Component} from "react";
import GoogleMapReact, {convertNeSwToNwSe, NESWBounds, MapOptions, Maps} from "google-map-react";
import {GERMANY_CENTER, GIESSEN_CENTER} from "../constants";
import {Tankstelle, TankstellenService} from "../Services/TankstellenService";
import Supercluster from "supercluster";
import {ClusterMarker} from "./ClusterMarker";
import Marker from "./Marker";

interface TankstellenPoint extends Tankstelle {
  isCluster: boolean;
}

export interface MapProps {
  mapCenter: GoogleMapReact.Coords,
  defaultZoom: number,
  tankstellenByQuery: Tankstelle[],
  isSearchActive: boolean,
  hasSearch: boolean,
  onVisibleTankstellenChange: (tankstellen: Tankstelle[]) => void,
  onCurrentTankstelleChange: (tankstelle: Tankstelle | null) => void,
  currentTankstelle?: Tankstelle | null,
}

export interface MapState {
  bounds: number[],
  zoom: number,
  map: unknown,
  maps: unknown,
  superCluster: Supercluster<Tankstelle, Tankstelle>,
  clusters: Array<Supercluster.ClusterFeature<Tankstelle>>,
  tankstellenByQuery: any,
}

// @ts-ignore
function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    maxZoom: 15
  };
}

export class Map extends Component<MapProps, MapState> {
  static defaultProps = {
    mapCenter: GERMANY_CENTER,
    defaultZoom: 7,
    currentTankstelle: null,
    tankstellenByQuery: null,
    isSearchActive: false,
    hasSearch: false,
  };

  state: MapState = {
    bounds: [],
    zoom: this.props.defaultZoom,
    map: undefined,
    maps: undefined,
    superCluster: new Supercluster({ radius: 100, maxZoom: 20 }),
    clusters: [],
    tankstellenByQuery: []
  };



  componentDidMount() {

    const points = TankstellenService
      .getInstance()
      .tankstellenToSuperclusterPointFeature()
      .map((p) => (
        {
          ...p,
          cluster: false
        }
      ));
    this.state.superCluster.load(points);

    // @ts-ignore
    const clusters = this.state.superCluster.getClusters(this.state.bounds, this.state.zoom);

    // @ts-ignore
    this.setState(() => ({ clusters: this.state.superCluster.getClusters(this.state.bounds, this.state.zoom) }));


  }

  componentDidUpdate(prevProps: Readonly<MapProps>, prevState: Readonly<MapState>) {
    if (prevState.bounds !== this.state.bounds || prevState.zoom !== this.state.zoom) {
      // @ts-ignore
      const clusters = this.state.superCluster.getClusters(this.state.bounds, this.state.zoom);

      // @ts-ignore
      this.setState(() => ({ clusters: clusters }));
    }



    if(this.props.isSearchActive || prevProps.tankstellenByQuery !== this.props.tankstellenByQuery) {

      if(prevProps.tankstellenByQuery === this.props.tankstellenByQuery) {


        // @ts-ignore
        //const clusters = this.state.superCluster.getClusters(this.state.bounds, this.state.zoom);
        // @ts-ignore
        //const tankstellen: Tankstelle[] = clusters.map((cluster) => cluster.properties).filter((c) => !c?.cluster);

        // @ts-ignore
        //this.props.onVisibleTankstellenChange(tankstellen);

        this.render();
        return;

      }



      // @ts-ignore
      var pointsFiltered = [];
      var points = TankstellenService
          .getInstance()
          .tankstellenToSuperclusterPointFeature()
          .map((p) => (
              {
                ...p,
                cluster: false
              }
          ));
      points.map((child) => {

        // TODO: Why index 0 and 1? What does the 180 mean?
        if(!(child.geometry.coordinates[0]>180 || child.geometry.coordinates[1]>180)) {
          if (this.handleCheck(child.properties.id)) {
            pointsFiltered.push(child);
          }
        }
      });

      // @ts-ignore
      points = pointsFiltered;

      this.state.superCluster.load(points);
      // @ts-ignore
      const clusters = this.state.superCluster.getClusters(this.state.bounds, this.state.zoom);


      // @ts-ignore
      this.setState(() => ({ clusters: this.state.superCluster.getClusters(this.state.bounds, this.state.zoom) }));



      if(pointsFiltered.length<1) {
        this.fitMapToInitialBounds(this.state.map, this.state.maps);
        return;
      }

      this.fitMapToBounds(this.state.map, this.state.maps, points);

      return;



    }


    if (prevState.bounds === this.state.bounds && prevState.zoom === this.state.zoom) return;

    // @ts-ignore
    const clusters = this.state.superCluster.getClusters(this.state.bounds, this.state.zoom);

    // @ts-ignore
    var tankstellenFiltered = [];
    // @ts-ignore
    const tankstellen: Tankstelle[] = clusters.map((child) => {
      // @ts-ignore
      if(child.properties.cluster) {
        // @ts-ignore
        tankstellenFiltered = tankstellenFiltered.concat(this.breakofCluster(this.state.superCluster.getChildren(child.properties.cluster_id)));
      } else {
        // @ts-ignore
        tankstellenFiltered.push(child.properties);
      }


    });

    // @ts-ignore
    if(this.state.zoom<10 && tankstellenFiltered.length>100) {
      tankstellenFiltered=[];
    }


    // @ts-ignore
    this.props.onVisibleTankstellenChange(tankstellenFiltered);


    let tfh = document.getElementById("tankstellenfinder");

    // @ts-ignore
    tfh.setAttribute("class", "tankstellenfinder main");
    if(this.props.hasSearch || (this.state.zoom>9)) {
      // @ts-ignore
      tfh.setAttribute("class", "tankstellenfinder main has-search");
    }


    // @ts-ignore
    this.setState(() => ({ clusters: this.state.superCluster.getClusters(this.state.bounds, this.state.zoom) }));



  }

  breakofCluster(cluster: unknown) {
    // @ts-ignore
    var tankstellenFiltered = [];
    // @ts-ignore
    cluster.map((child) => {
      // @ts-ignore
      if(child.properties.cluster) {
        // @ts-ignore
        tankstellenFiltered = tankstellenFiltered.concat(this.breakofCluster(this.state.superCluster.getChildren(child.properties.cluster_id)));
      } else {
        // @ts-ignore
        tankstellenFiltered.push(child.properties);
      }
    });
    // @ts-ignore
    return tankstellenFiltered;
  }

  handleCheck(val: number) {
    // @ts-ignore
    return this.props.tankstellenByQuery.some(item => val === item.id);
  }
  // @ts-ignore
  getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    // @ts-ignore
    places.forEach((place) => {
      const [lng, lat] = place.geometry.coordinates;
      bounds.extend(new maps.LatLng(
          lat,
          lng,
      ));
    });
    return bounds;
  };
  // @ts-ignore
  fitMapToBounds = (map, maps, places) => {
    // @ts-ignore
    const bounds = this.getMapBounds(map, maps, places);
    map.fitBounds(bounds, {top:0,right:0,left:0});
    // @ts-ignore
    this.bindResizeListener(map, maps, bounds);

  };
  // @ts-ignore
  fitMapToInitialBounds = (map, maps) => {
    // @ts-ignore
    const bounds = new maps.LatLngBounds();
    bounds.extend(new maps.LatLng(
        47.58526940077465,
        0.565480851562512,
    ));
    bounds.extend(new maps.LatLng(
        54.48395246717672,
        20.329885148437512,
    ));
    map.fitBounds(bounds, {top:0,right:0,left:0});
    // @ts-ignore
    this.bindResizeListener(map, maps, bounds);

  };
  // @ts-ignore
  bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds, {top:0,right:0,left:0});
      });
    });
  };

  render() {

    return (
      <>
        <div className="map">
          <GoogleMapReact
            bootstrapURLKeys={{key: 'AIzaSyC7rR9P1mj7s6Y6KfRp2pUqqo2ZqHh8c8Y'}}
            defaultCenter={this.props.mapCenter}
            defaultZoom={this.props.defaultZoom}
            zoom={this.state.zoom}
            options={createMapOptions}
            onChildMouseEnter={(_, childProps) => this.props.onCurrentTankstelleChange(childProps.cluster.properties)}
            onChildMouseLeave={() => this.props.onCurrentTankstelleChange(null)}
            onGoogleApiLoaded={({ map, maps }) => {

              this.setState(() => ({ map: map, maps: maps }));
              //this.fitMapToBounds(map, maps, this.state.clusters);

            }}
            onChange={({ zoom, bounds }) => {
              this.setState(() => ({
                zoom: zoom,
                bounds: [ bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat ],
              }));



            }}
          >
            { this.state.clusters.map((cluster) => {
              const [lng, lat] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } = cluster.properties;


              if (isCluster) {
                return (<ClusterMarker key={cluster.id} lat={lat} lng={lng} cluster={cluster} superCluster={this.state.superCluster} map={this.state.map}/>)
              }

              return (
                <Marker key={cluster.id} lat={lat} lng={lng} cluster={cluster} isActive={cluster.properties.id === this.props.currentTankstelle?.id}/>
              )
            })}
          </GoogleMapReact>
        </div>
      </>
    );
  }
}