import {Component} from "react";
import {Tankstelle} from "../Services/TankstellenService";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faClock} from '@fortawesome/free-solid-svg-icons';
import {OpenOrClosed} from "./OpenOrClosed";

export interface TankstelleSidebarProps extends RouteComponentProps {
  tankstelle: Tankstelle,
  isActive?: boolean,
}

class TankstelleSidebar extends Component<TankstelleSidebarProps> {
  static defaultProps = {
    isActive: false,
  }

  toDetailPage = () => {
    const url = `/tankstellen/${this.props.tankstelle.slug}`;
    this.props.history.push(url);
  };

  render() {
    return (
      <div onClick={this.toDetailPage} className={ this.props.isActive ? 'active' : '' }>
        <h4 className={'name'}>{this.props.tankstelle.name}</h4>
        <p className={'address'}>
          {this.props.tankstelle.street}<br/>
          {this.props.tankstelle.zip} {this.props.tankstelle.city}
        </p>
        <OpenOrClosed tankstelle={this.props.tankstelle}/>
      </div>
    );
  }
}

export default withRouter(TankstelleSidebar);