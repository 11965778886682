import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import {Main} from "./Views/Main";
import Detail from "./Views/Detail";
import {TankstellenService} from "./Services/TankstellenService";

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    TankstellenService.getInstance().init().then(() => {
      setLoading(false);
    })
  }, []);

  return (
    <>
      {
        !isLoading
          ? (
            <Switch>
              <Route exact path="/">
                <Main/>
              </Route>
              <Route path="/tankstellen/:slug">
                <Detail/>
              </Route>
            </Switch>
          )
          : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <h1>Loading...</h1>
              </div>
            </div>
          )
      }
    </>
  );
}

export default App;
