import {Tankstelle} from "../Services/TankstellenService";
import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';

interface OpenOrClosedProps {
    tankstelle: Tankstelle;
}

export class OpenOrClosed extends Component<OpenOrClosedProps> {
  getOpenOrClosedClass():string {
    return this.props.tankstelle.isOpen() ? 'status-open' : 'status-closed';
  }
  getOpenOrClosedText():string {
    return this.props.tankstelle.isOpen() ? 'geöffnet' : 'geschlossen';
  }

  getOpenOrClosedTextDom():JSX.Element {
    const openClass = this.getOpenOrClosedClass();
    const openText  = this.getOpenOrClosedText();
    return <span className={`station-status ${openClass}`}>{openText}</span>
  }

  hasDescription():boolean {
    if (this.props.tankstelle.openingHoursDescription === null || this.props.tankstelle.openingHoursDescription === '') {
      return false;
    }
    else { return true; }
  }
  

  render() {
    return (
      <>
      {this.hasDescription() ? <span>{this.props.tankstelle.openingHoursDescription}</span> : ''}
      <p className={'openhours mb-3 ' + this.getOpenOrClosedClass()}>
        <FontAwesomeIcon icon={faClock} />
        <span className={'station-status ' + this.getOpenOrClosedClass()}>{this.getOpenOrClosedText()}</span>
      </p>
    </>
    )
    
  }
}