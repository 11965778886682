import {useParams} from "react-router-dom";

export interface Params {
  params: {
    [key: string]: string;
  };
}

const withParams = (Component: any) => {
  return (props: unknown) => {
    const usedParams = useParams();
    return <Component params={usedParams} {...props}/>
  }
}

export default withParams;