import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight, faArrowCircleLeft, faArrowLeft, faArrowLeftRotate,
  faCheck,
  faClock,
  faCoffee,
  faCreditCard,
  faOilCan,
  faRestroom,
  faTruckMoving,
  faShop,
  faBurger,
  faDroplet,
  faTruckDroplet,
  faCarTunnel,
  faUtensils,
  faBroom,
  faMugSaucer,
  faTruck

} from '@fortawesome/free-solid-svg-icons';

interface MediaIconsInterface {
  getIconByKey(key:string):JSX.Element;
}

export class MediaIcons {
    
  static getPaymentImagesByKey(key: string): JSX.Element {
      switch (key) {
        case 'CASH':
          return <img src='/cash.jpg' className='payment-image'/>
          break;
        case 'DKV':
          return <img src='/dkv.jpg' className='payment-image'/>
          break;
        case 'EC':
          return <img src='/ec.jpg' className='payment-image'/>
          break;
        case 'MAESTRO':
          return <img src='/maestro.jpg' className='payment-image'/>
          break;
        case 'MASTERCARD':
          return <img src='/mastercard.jpg' className='payment-image'/>
          break;
        case 'novofleet':
          return <img src='/novofleet.jpg' className='payment-image'/>
          break;
        case 'ROTH Tankkarte':
          return <img src='/tankkarte.jpg' className='payment-image'/>
          break;
        case 'ROTH CARD':
          return <img src='/roth_card.png' className='payment-image'/>
          break;
        case 'Tankpool24':
          return <img src='/tankpool24.jpg' className='payment-image'/>
          break;
        case 'UTA':
          return <img src='/uta.jpg' className='payment-image'/>
          break;
        case 'VISA':
          return <img src='/visa.jpg' className='payment-image'/>
          break;
        case 'AMEX':
          return <img src='/amex.jpg' className='payment-image'/>
          break;
        case 'Q1':
          return <img src='/q1.svg' className='payment-image'/>
          break;
        default:
          return <FontAwesomeIcon icon={faCreditCard}/>
          break;
      }
  }

  static getServiceIconByKey(key: string): JSX.Element {
      switch (key) {
          case 'Bistro':
          return <FontAwesomeIcon icon={faMugSaucer}/>
          break;
          case 'Energielieferant':
          return <FontAwesomeIcon icon={faBurger}/>
          break;
          case 'Staubsauger':
          return <FontAwesomeIcon icon={faBroom}/>
          break;
          case 'Waschstrasse':
          return <FontAwesomeIcon icon={faCarTunnel}/>
          break;
          case 'Waschstraße':
          return <FontAwesomeIcon icon={faCarTunnel}/>
          break;
          case 'WaschanlageLKW':
          return <FontAwesomeIcon icon={faTruck}/>
          break;
          case 'Waschanlage':
          return <FontAwesomeIcon icon={faCarTunnel}/>
          break;
          case 'WC':
          return <FontAwesomeIcon icon={faRestroom}/>
          break;
          case 'Shop':
          return <FontAwesomeIcon icon={faShop}/>
          break;
          default:
          return <img src='' className='payment-image'/>
          break;
      }
  }

  static getShopOrCardsElement(shopOrCards: 'Shop' | 'Karte'): JSX.Element {
      let icon: JSX.Element | null = null;
      let name: string;
      switch (shopOrCards) {
          case 'Shop':
          icon = <FontAwesomeIcon icon={faShop}/>;
          name = 'Shop';
          break;
          case 'Karte':
          icon = <FontAwesomeIcon icon={faCreditCard}/>;
          name = 'Karten';
          break;
      }
  
      if (!icon) {
          return <></>;
      }
      
      return <div className={"mb-3"}>{icon}<span>{name}-Tankstelle</span></div>;
  }

  static getIconClock() {
    return <FontAwesomeIcon icon={faClock}/>;
  }
}