import GoogleMapReact, {Point} from "google-map-react";
import Supercluster from "supercluster";
import slugify from "slugify";

export interface TankstellenProducts {
  features: {
    shop: boolean;
    cafe: boolean;
    schmierstoffe: boolean;
    toiletten: boolean;
    parking: boolean;
    lkw_tauglich: boolean;
  }
  fuels: {
    diesel: boolean;
    super_e10: boolean;
    super_e5: boolean;
    super_plus: boolean;
    adblue_pumpe: boolean;
    adblue_kanister: boolean;
  }
}

export interface TankstellenNetwork {
  clearing: string;
  kassensystem: string;
}

export interface Tankstelle {
  display: boolean;
  id: number;
  slug: string;
  name: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  coords: GoogleMapReact.Coords;
  phone: string;
  website?: string;
  email: string;
  openingHoursDescription?: string;
  imageUrl?: string;
  payments: string[];
  roth_card?: string;
  services: string[];
  products: string[];
  shopOrCards: 'Shop' | 'Karte',
  uberallUrl?: string;
  open24h: boolean,
  openingHours: {
    monday: string[],
    tuesday: string[],
    wednesday: string[],
    thursday: string[],
    friday: string[],
    saturday: string[],
    sunday: string[],
  },
  someOpeningHoursAvailable(): boolean;
  isOpen(): boolean;
}

export class TankstellenService {
  private static instance: TankstellenService;
  private tankstellen: Tankstelle[];

  private constructor() {
    this.tankstellen = [];
  }

  static getInstance(): TankstellenService {
    if (!this.instance) this.instance = new TankstellenService();
    return this.instance;
  }

  static tankstelleToSuperclsuterPointFeature(tankstelle: Tankstelle): Supercluster.PointFeature<Tankstelle> {
    return {
      type: 'Feature',
      properties: tankstelle,
      geometry: {
        type: 'Point',
        coordinates: [
          tankstelle.coords.lng,
          tankstelle.coords.lat
        ]
      }
    }
  }

  private async fetchTankstellen(): Promise<Tankstelle[]> {
    const res = await fetch(window.location.origin + '/data.json');
    const data = await res.json();

    return data.tankstellen.map((d: Record<string, any>, i: number): Tankstelle => (
      {
        display: true,
        id: i,
        slug: slugify(`${d['name']} ${i}`, {lower: true}),
        name: d['name'],
        street: d['street'],
        zip: d['zip'],
        city: d['city'],
        country: d['country'],
        coords: {
          lat: d['lat'],
          lng: d['lng'],
        },
        phone: d['phone'],
        website: d['website'],
        email: d['email'],
        open24h: d['openinghours24h'],
        openingHours: {
          monday: d['openinghoursmonday'],
          tuesday: d['openinghourstuesday'],
          wednesday: d['openinghourswednesday'],
          thursday: d['openinghoursthursday'],
          friday: d['openinghoursfriday'],
          saturday: d['openinghourssaturday'],
          sunday: d['openinghourssunday'],
        },
        openingHoursDescription: d['openinghoursdescription'],
        imageUrl: d['imageurl'],
        payments: d['payments'],
        roth_card: d['roth_card'],
        services: d['services'],
        products: d['products'],
        shopOrCards: d['shoporcards'],
        uberallUrl: d['uberallurl'],
        someOpeningHoursAvailable(): boolean {
          return this.open24h || (Object.values(this.openingHours).some((openingHours) =>
            openingHours.length > 0 && openingHours.some((openingHour) => openingHour !== '')
          ));
        },
        isOpen(): boolean {
          if (this.shopOrCards === 'Karte') return true;
          if (!this.someOpeningHoursAvailable()) return false;

          const nameOfCurrentDay = (new Date()).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
          // @ts-ignore
          const openingHours = this.openingHours[nameOfCurrentDay] as string[];

          return openingHours.some((openingHour) => {
            const [open, close] = openingHour.split('-').map((openingHour) => openingHour.trim());

            const date = new Date();
            date.setFullYear(2000, 0, 1);

            const openDate = new Date(`2000-01-01T${open}`);
            const closeDate = new Date(`2000-01-01T${close}`);

            return this.open24h || (date >= openDate && date < closeDate);
          });
        }
      }
    ));
  }

  async init(): Promise<void> {
    this.tankstellen = await this.fetchTankstellen();
  }

  tankstellenToSuperclusterPointFeature(): Supercluster.PointFeature<Tankstelle>[] {
    return this.tankstellen.map(TankstellenService.tankstelleToSuperclsuterPointFeature);
  }

  add(tankstelle: Tankstelle): void {
    this.tankstellen = [ ...this.tankstellen, tankstelle ];
  }

  getAll(): Tankstelle[] {
    return this.tankstellen;
  }

  getById(id: number): Tankstelle | undefined {
    return this.tankstellen.find((tankstelle) => tankstelle.id === id);
  }

  getBySlug(slug: string): Tankstelle | undefined {
    return this.tankstellen.find((tankstelle) => tankstelle.slug === slug);
  }

  createRouteUrlById(id: number): string | undefined {
    const tankstelle = this.getById(id);
    if (!tankstelle) return undefined;
    return `https://maps.google.com/maps?daddr=${tankstelle.street}, ${tankstelle.zip} ${tankstelle.city}`;
  }

  getAllServices(): string[] {
    const allServices = this.tankstellen
      .flatMap((tankstelle) => tankstelle.services)
      .filter((service) => service !== '');
    const services = new Set(allServices);
    return Array.from(services);
  }

  getAllProducts(): string[] {
    const allProducts = this.tankstellen
      .flatMap((tankstelle) => tankstelle.products)
      .filter((product) => product !== '');
    const products = new Set(allProducts);
    return Array.from(products);
  }
}
