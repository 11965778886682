import React, {Component} from "react";
import TranslationService from "../Services/TranslationService";


export interface FilterProps<T> {
  name: string;
  options: {[key: string]: T};
  defaultOptions?: T[];
  onFilter: (options: T[]) => any,
  renderOption?: (key: string) => JSX.Element | string,
}

export interface FilterState<T> {
  selectedOptions: T[];
}

export class Filter<T> extends Component<FilterProps<T>, FilterState<T>> {
  static defaultProps = {
    defaultOptions: [],
    onFilter: () => {},
    renderOption: (key: string) => key,
  }

  state: FilterState<T> = {
    // @ts-ignore
    selectedOptions: this.props.defaultOptions,
  }

  selectOption = (value: T): void => {
    let newSelectedOptions = this.state.selectedOptions;

    if (this.isOptionSelected(value)) {
      newSelectedOptions = newSelectedOptions.filter((option) => option !== value);
    } else {
      newSelectedOptions = [...newSelectedOptions, value];
    }

    this.setState(() => ({ selectedOptions: newSelectedOptions }));
    
    this.props.onFilter(newSelectedOptions);
  };

  isOptionSelected = (value: T): boolean => {
    return this.state.selectedOptions.some((option) => option === value);
  }
  getTitle = (name:string):JSX.Element => name ? <h3 className="h4">{name}</h3> : <></>;

  render() {
    return (
      <>
      <div className="filter-wrapper">
        { this.getTitle(this.props.name) }
        {
          Object.entries(this.props.options).map(([key, value]) => {
              return (
                <label key={key} className={`col-xs-12 col-6 ${key} filter-${this.isOptionSelected(value) ? 'selected' : 'unselected'}`}>
                  <input
                    type="checkbox"
                    onClick={() => this.selectOption(value)}
                  />
                  <span className="checkmark"></span>
                  {
                    // @ts-ignore
                    typeof this.props.renderOption(key) === 'string' ? TranslationService.translate(key) : this.props.renderOption(key)
                  }
                </label>
              )
          })
        }
      </div> 
      </>
    )
  }
}