import React, {Component} from "react";
import {Filter, FilterProps, FilterState} from "./Filter";
import Button from 'react-bootstrap/Button';
import Offcanvas, { OffcanvasProps } from 'react-bootstrap/Offcanvas';
import TranslationService from "../Services/TranslationService";
import {TankstellenService} from "../Services/TankstellenService";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faXmark} from '@fortawesome/free-solid-svg-icons';

interface FilterMenuOption extends FilterProps<string> {
    getIcon?: (key: string) => JSX.Element,
}

export interface FilterMenuProps {
    label: string;
    filterOptions: FilterMenuOption[],
    offCanvasOptions: OffcanvasProps
}
export interface FilterMenuState {
    show: boolean;
    
}

export class FilterMenu extends Component<FilterMenuProps , FilterMenuState> {
      
    static defaultProps = {
        offCanvasOptions : {
            "placement": "end",
            "backdrop": false,
            "enforceFocus": false,
        }
    }

    state: FilterMenuState = {
        show: false,
    }
    
    handleClose = ():void => { this.setState(() => ({ show: false })); };
    handleShow  = ():void => { this.setState(() => ({ show: !this.state.show })); };

    render() {
        return (
            <>
            <Button className="btn-filter" variant="" onClick={this.handleShow}>
                <FontAwesomeIcon icon={faFilter}/>
            </Button>
            <Offcanvas show={this.state.show} onHide={this.handleClose} {...this.props.offCanvasOptions} >
                <Offcanvas.Header className="pb-0">
                    <Offcanvas.Title>
                        <h2 className="h4">{this.props.label}</h2>
                        <Button className="btn-close" variant="" onClick={this.handleShow}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </Button>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0">
                    {this.props.filterOptions.map((filterOption, idx) => (
                        <Filter
                            key={idx}
                            name={filterOption.name}
                            options={filterOption.options}
                            onFilter={filterOption.onFilter}
                            defaultOptions={filterOption.defaultOptions}
                            renderOption={(key) => {
                                if(filterOption?.getIcon) {
                                    return (<>{filterOption.getIcon(key)} {key}</>)
                                } else {
                                    return key;
                                }
                            }}
                        />
                    ))}
                </Offcanvas.Body>
            </Offcanvas>            
        </>
        )
    }
}