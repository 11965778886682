import {Tankstelle} from "../Services/TankstellenService";
import {Component} from "react";

export interface TankstelleShortDetailsProps {
  tankstelle: Tankstelle;
}

class TankstelleShortDetails extends Component<TankstelleShortDetailsProps> {
  render() {
    return (
      <div className={'station-overlay'}>
        <p>{this.props.tankstelle.name}</p>
      </div>
    );
  }
}

export default TankstelleShortDetails;