class TranslationService {
  private static translations: {[key: string]: string} = {
    EC: "EC",
    MAESTRO: "MAESTRO",
    MASTERCARD: "MASTERCARD",
    ROTHTankkarte: "ROTHTankkarte",
    UTA: "UTA",
    VISA: "VISA",

    Bistro: "Bistro",
    WaschanlageLKW: "WaschanlageLKW",
    Waschanlage: "Waschanlage",
    WC: "WC",
    Waschstraße: "Waschstraße",
    Waschstrasse: "Waschstrasse",
    Staubsauger: "Staubsauger",
    Energielieferant: "Energielieferant",

    SuperE10: "SuperE10",
    SuperE5: "SuperE5",
    SuperPlus: "SuperPlus",
    Diesel: "Diesel",
    LPG: "LPG",
    LNG: "LNG",
    CNG: "CNG",
    AutogasLPG: "AutogasLPG",
    HEL: "HEL",
    BlueDiesel: "BlueDiesel",
    AdBlueLKW: "AdBlueLKW",
    AdBlue: "AdBlue",
    AdbluePumpe: "Adblue Pumpe",
    AdblueKanister: "Adblue Kanister",
    HVO100Diesel: "HVO 100 Diesel",

    Shop: 'Shop',
    Cafe: 'Cafe',
    Schmierstoffe: 'Schmierstoffe',
    Toiletten: 'Toiletten',
    Parking: 'Parken',
    Lkw_tauglich: 'LKW Tauglich',

    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  };

  public static translate(key: string): string {
    const value = TranslationService.translations[key];
    //if (!value) console.warn(`No translation for key "${key}" found`);
    return value ?? key;
  }

  public static translateMany(keys: string[]): string[] {
    return keys.map(this.translate);
  };
}

export default TranslationService;
