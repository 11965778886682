import {Tankstelle, TankstellenService} from "../Services/TankstellenService";
import React, {Component} from "react";
import {DumpJson} from "../Utils/DumpJson";
import { Card, Col, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import TranslationService from "../Services/TranslationService";
import {OpenOrClosed} from "./OpenOrClosed";
import {MediaIcons}  from "../Utils/MediaIcons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight, 
  faArrowLeft,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

interface TankstelleAttributeProps {
  title?: string;
}

class TankstelleAttribute extends Component<TankstelleAttributeProps> {
  render() {
    return (
      <Card className="border-0">
        <Card.Body>
          { this.props.title && <Card.Title>{this.props.title}</Card.Title> }
          {this.props.children}
        </Card.Body>
      </Card>
    );
  }
}

interface TankstelleDetailsProps {
  tankstelle: Tankstelle;
}


export class TankstelleDetails extends Component<TankstelleDetailsProps> {

  getReorderdPayments(keys: Array<string>) {
    let transformedKeys:Array<string>  = [];
    let originalKeys:Array<string>  = [];

    keys.map((val) => {
      let ikey = val.toLowerCase().split(' ').join('').replace('-','');
      transformedKeys.push(ikey);
      originalKeys.push(val);
    });
    if (transformedKeys.includes('rothtankkarte')) {
      let cardIndex = transformedKeys.indexOf('rothtankkarte');
      originalKeys.splice(cardIndex, 1);
      originalKeys.unshift('ROTH Tankkarte')
    }
    return originalKeys;
  }

  isMondayToFridaySame(tankstelle: Tankstelle): boolean {
    return Object.entries(tankstelle.openingHours).every(([key, value]) => {
      if (key !== 'saturday' && key !== 'sunday') {
        if (JSON.stringify(value) === JSON.stringify(tankstelle.openingHours.monday)) {
          return true;
        }
      }
      else { return true; }
    })
  }

  getWeekHours(tankstelle: Tankstelle) {
    if (!tankstelle.open24h) {
      if (!this.isMondayToFridaySame(tankstelle)) {
        return Object.entries(tankstelle.openingHours).map(([key, value]) => {
          if (key !== 'saturday' && key !== 'sunday') {
            return (
              <div className="row">
                <dt className="col-sm-5">{TranslationService.translate(key)}</dt>
                {value.map( el => {
                  return <dd className="col-sm-7 mb-1 mb-sm-0">{el}</dd>
                })}
              </div>
            )
          }
        })
      }
      else {
        return (
          <div className="row">
            <dt className="col-sm-6">{TranslationService.translate('monday')} - {TranslationService.translate('friday')}</dt>
            {tankstelle.openingHours.monday.map( el => {
              return <dd className="col-sm-6 mb-1 mb-sm-0">{el}</dd>
            })}
          </div>
        )
      }
    }
    
  }
  getWeekendHours(tankstelle: Tankstelle) {
    if (!tankstelle.open24h && this.isMondayToFridaySame(tankstelle)) {
      return (
        <>
          <div className="row">
            <dt className="col-sm-6">{TranslationService.translate('saturday')}</dt>
            {tankstelle.openingHours.saturday.map( el => {
              return <dd className="col-sm-6 mb-1 mb-sm-0">{el}</dd>
            })}
          </div>
          <div className="row">
            <dt className="col-sm-6">{TranslationService.translate('sunday')}</dt>
            {tankstelle.openingHours.sunday.map( el => {
              return <dd className="col-sm-6 mb-1 mb-sm-0">{el}</dd>
            })}
          </div>
        </>
      )
    }
    else if (!tankstelle.open24h && !this.isMondayToFridaySame(tankstelle)) {
      return (
        <>
          <div className="row">
            <dt className="col-sm-5">{TranslationService.translate('saturday')}</dt>
            {tankstelle.openingHours.saturday.map( el => {
              return <dd className="col-sm-7 mb-1 mb-sm-0">{el}</dd>
            })}
          </div>
          <div className="row">
            <dt className="col-sm-5">{TranslationService.translate('sunday')}</dt>
            {tankstelle.openingHours.sunday.map( el => {
              return <dd className="col-sm-7 mb-1 mb-sm-0">{el}</dd>
            })}
          </div>
        </>
      )
    }
  }

  getOpeningHoursDoms(tankstelle: Tankstelle):JSX.Element {
    return (
      <>
      {this.getWeekHours(tankstelle)}
      {this.getWeekendHours(tankstelle)}
      </>
    )
  }

  render() {
    return (
      <div className={'content'}>
        <div className={'container'}>
          <Row>
            <Col xl={{ span: 10, offset: 1 }}>

              <Card className={'station_detail'}>

                <div className={'card-header'}>
                  <h1>Tankstelle <strong>{this.props.tankstelle.name}</strong></h1>
                  <address>{this.props.tankstelle.street}, {this.props.tankstelle.zip} {this.props.tankstelle.city}</address>
                </div>

                <Card.Body>
                  <Row>

                    <Col lg={{ span: 4 }}>
                      <div className={'station_images'}>
                        <Image className={'img-fluid'} src={this.props.tankstelle.imageUrl || '/default_tankstelle.jpg'} />
                      </div>
                      <a className={'btn btn-primary mb-3'} href={TankstellenService.getInstance().createRouteUrlById(this.props.tankstelle.id)} target="_blank" rel="noreferrer">Route planen</a>
                      { this.props.tankstelle.website && <a className={'station-link'} href={this.props.tankstelle.website} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowCircleRight}/> Weitere Informationen</a> }
                    </Col>

                    <Col lg={{ span: 4, offset: 1 }} sm={{ span: 6}}>
                      {this.props.tankstelle.someOpeningHoursAvailable() && (
                        <>
                          <h3 className={'attribute_hl mt-5 mt-lg-0'}>Öffnungszeiten</h3>
                          <OpenOrClosed tankstelle={this.props.tankstelle}/>
                          <dl>
                          <dt>{this.props.tankstelle.open24h ? '24h geöffnet' : ''}</dt>
                            {this.getOpeningHoursDoms(this.props.tankstelle)}
                          </dl>
                        </>
                      )}

                      {MediaIcons.getShopOrCardsElement(this.props.tankstelle.shopOrCards)}

                      <h3 className={'attribute_hl'}>Kraftstoffe</h3>
                      <ul className={'fuels'}>
                        {
                          this.props.tankstelle.products.map((product, index) => (
                            <li key={index}>
                              <FontAwesomeIcon icon={faCheck} />{product}
                            </li>
                          ))
                        }
                      </ul>
                    </Col>

                    <Col lg={{ span: 3 }} sm={{ span: 6}}>
                      <h3 className={'attribute_hl mt-5 mt-lg-0'}>Zahlungsarten</h3>
                      <ul className={'payments'}>
                        {
                          this.getReorderdPayments(this.props.tankstelle.payments).map((payment, index) => (
                            <li className={payment}>
                              {MediaIcons.getPaymentImagesByKey(payment)}
                              {payment}
                            </li>
                          ))
                        }
                      </ul>
                    </Col>

                    {
                      // Only show services when tankstelle is a shop with services available
                      // NO, the excel says different!
                      ((this.props.tankstelle?.services.length >= 1 && this.props.tankstelle.services[0] !== '')) &&
                      (
                        <Col lg={{ span: 7, offset: 5 }}>
                          <div className={'mt-5'}>
                            <h3 className={'attribute_hl'}>Service-Angebot</h3>
                            <ul className={'features'}>
                              {
                                this.props.tankstelle.services.map((service, index) => {
                                    if (service === 'Shop') return;
                                    return (<li key={index} className={service}>
                                      {MediaIcons.getServiceIconByKey(service)}
                                      {service}
                                    </li>)
                                })
                              }
                            </ul>
                          </div>
                        </Col>
                      )
                    }
                  </Row>

                </Card.Body>

                <div className={'backlink'}>
                  <Link className={'btn btn-primary btn-sm'} to="/"><FontAwesomeIcon icon={faArrowLeft} /> zurück</Link>
                </div>

              </Card>
              {/*
                <DumpJson data={this.props}/>
              */}
              

            </Col>
          </Row>

        </div>
      </div>
    );
  }
}