import {Component} from "react";
import Supercluster, {ClusterFeature} from "supercluster";
import {Tankstelle} from "../Services/TankstellenService";

export interface ClusterMarkerProps {
  lat: number;
  lng: number;
  cluster: ClusterFeature<Tankstelle>;
  superCluster: Supercluster;
  map: unknown,
}

export interface ClusterMarkerState {}

export class ClusterMarker extends Component<ClusterMarkerProps, ClusterMarkerState> {
  static defaultProps = {};

  getExpansionZoom = () => {
    return Math.min(this.props.superCluster.getClusterExpansionZoom(this.props.cluster.properties.cluster_id) + 1, 20);
  };

  zoom = () => {
    // @ts-ignore
    this.props.map.setZoom(this.getExpansionZoom());
    // @ts-ignore
    this.props.map.panTo({ lat: this.props.lat, lng: this.props.lng });
  };

  render() {
    return (
      <div className="cluster-marker" onClick={this.zoom}>
        {this.props.cluster.properties.point_count}
      </div>
    );
  }
}
