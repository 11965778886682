import GoogleMapReact from "google-map-react";

export const GIESSEN_CENTER: GoogleMapReact.Coords = {
  lat: 50.5754007,
  lng: 8.6649377
};

export const GERMANY_CENTER: GoogleMapReact.Coords = {
  lat: 51.163361,
  lng: 10.447683
};
