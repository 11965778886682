import {Component} from "react";
import {Search} from "../Components/Search";
import {Map} from "../Components/Map";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import {Tankstelle, TankstellenService} from "../Services/TankstellenService";
import TankstelleSidebar from "../Components/TankstelleSidebar";
import {Filter} from "../Components/Filter";
import {FilterMenu} from "../Components/FilterMenu";
import TranslationService from "../Services/TranslationService";
import { MediaIcons } from "../Utils/MediaIcons";

export interface MainProps {}

export interface MainState {
  isSearchActive: boolean,
  hasSearch: boolean,
  visibleTankstellen: Tankstelle[],
  currentTankstelle: Tankstelle | null,
  tankstellenByQuery: Tankstelle[],
  productsFilterOptions: string[],
  servicesFilterOptions: string[],
  isOpenFilter: boolean,
  lastQuery: string,
}

export class Main extends Component<MainProps, MainState> {
  static defaultProps = {};

  state: MainState = {
    isSearchActive: false,
    hasSearch: false,
    visibleTankstellen: [],
    currentTankstelle: null,
    tankstellenByQuery: [],
    productsFilterOptions: [],
    servicesFilterOptions: [],
    isOpenFilter: false,
    lastQuery: '',
  };

  handleVisibleTankstellenChange = (tankstellen: Tankstelle[]): void => {
    this.setState(() => ({ visibleTankstellen: tankstellen, isSearchActive: false }));
  };

  handleCurrentTankstelleChange = (tankstelle: Tankstelle | null): void => {
    this.setState(() => ({ currentTankstelle: tankstelle }));
  };

  handleSearch = (query: string): void => {
    this.setState(() => ({
      lastQuery: query,
      isSearchActive: !(!query || query.length <= 0),
      hasSearch: !(!query || query.length <= 0),
      tankstellenByQuery: this.filterTankstellenBySearchQuery(TankstellenService.getInstance().getAll(), query, [
        (tankstelle) => this.state.productsFilterOptions.length === 0 || this.state.productsFilterOptions.every((option) => {return tankstelle.products.includes(option)}),
        (tankstelle) => this.state.servicesFilterOptions.length === 0 || this.state.servicesFilterOptions.every((option) => {return tankstelle.services.includes(option)}),
        (tankstelle) => !this.state.isOpenFilter || tankstelle.isOpen(),
      ]),
    }));


    this.render();
  };

  doSearch = (): void => {
    this.handleSearch(this.state.lastQuery);
  };

  filterTankstellenBySearchQuery = (allTankstellen: Tankstelle[], query: string, additionalChecks?: ((tankstelle: Tankstelle) => boolean)[]): Tankstelle[] => {
    return allTankstellen.filter((tankstelle) => {
      if (!tankstelle.name || !tankstelle.street || !tankstelle.zip || !tankstelle.city) return;

      const terms: (string | number | null)[] = [ tankstelle.name.trim(), ...tankstelle.street.trim().split(' '), tankstelle.zip, tankstelle.city.trim() ];
      const termFound = terms.find((term) => {
        if (!term) return false;
        if (typeof term === 'number') term = term.toString();
        return term.toLowerCase().startsWith(
          query.toLowerCase()
        )
      });
      const checks = additionalChecks?.every((additionalCheck) => additionalCheck(tankstelle));
      return (query === '' || termFound) && checks;
    });
  };

  getTankstellenForSidebar = (): Tankstelle[] => {
    return this.state.isSearchActive ? this.state.tankstellenByQuery : this.state.visibleTankstellen;
  };

  arrayToMap(arr: string[]): {[key: string]: string} {
    return arr.reduce((pV, cV) => ({ ...pV, [cV]: cV }), {});
  }

  render() {
    return (
      <div className={"tankstellenfinder main"} id={"tankstellenfinder"}>
        <Map hasSearch={this.state.hasSearch} isSearchActive={this.state.isSearchActive} tankstellenByQuery={this.state.tankstellenByQuery} onVisibleTankstellenChange={this.handleVisibleTankstellenChange} onCurrentTankstelleChange={this.handleCurrentTankstelleChange} currentTankstelle={this.state.currentTankstelle}/>

        <div className={"sidebar"}>
          <div className={"sidebar-header"}>
            <Search onSearch={this.handleSearch} />

              <FilterMenu
              label="Filter"
              filterOptions={[
                {
                  name: '',
                  getIcon: MediaIcons.getIconClock,
                  options: { 'Jetzt geöffnet': 'isOpen' },
                  defaultOptions: this.state.isOpenFilter ? ['isOpen'] : [],
                  onFilter: (options) => {
                    this.setState(
                      () => ({ isOpenFilter: options.length === 1 }),
                      () => this.doSearch(),
                    );
                  },
                },
                {
                  name: 'Kraftstoffe',
                  options: this.arrayToMap(TankstellenService.getInstance().getAllProducts()),
                  defaultOptions: this.state.productsFilterOptions,
                  onFilter: (options) => {
                    this.setState(
                      () => ({ productsFilterOptions: options }),
                      () => this.doSearch(),
                    );
                  },
                },
                {
                  name: 'Services',
                  getIcon: MediaIcons.getServiceIconByKey,
                  options: this.arrayToMap(TranslationService.translateMany(TankstellenService.getInstance().getAllServices())),
                  defaultOptions: this.state.servicesFilterOptions,
                  onFilter: (options) => {
                    this.setState(
                      () => ({ servicesFilterOptions: options }),
                      () => this.doSearch(),
                    );
                  },
                },
              ]}
            />
          </div>
          
          
          <ListGroup>
          {
              (this.state.tankstellenByQuery.length == 0 && this.state.lastQuery.length > 0) 
              && 
              <div className="no-findings">
                <p>Keine Ergebnisse zu diesen Suchkriterien gefunden</p> 
              </div>
            }
            {
              this.getTankstellenForSidebar().map((tankstelle) => (
                <ListGroupItem
                  key={tankstelle.id}
                  onMouseEnter={() => this.handleCurrentTankstelleChange(tankstelle)}
                  onMouseLeave={() => this.handleCurrentTankstelleChange(null)}
                >
                  <TankstelleSidebar tankstelle={tankstelle} isActive={tankstelle.id === this.state.currentTankstelle?.id}/>
                </ListGroupItem>
              ))
            }
          </ListGroup>
        </div>
        <div className="filter">
        
        
        </div>
        
      </div>
    );
  }
}
